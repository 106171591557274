@import '../../styles/variable';

.create_page_container {
  min-height: calc(100vh - #{$header-height} - #{$footer-height});
  position: relative;
  .create_page {
    position: absolute;
    width: 100%;
    top: 50%;
    translate: 0 -50%;
  }
}

.icon_cover {
  font-size: 5rem;
  text-align: center;
  color: $info-color;
  background-color: #cccccc;
}
