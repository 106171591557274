.profile_container {
  padding-top: 2rem;
  .upload_avatar {
    position: relative;
    cursor: pointer;
    .upload_avatar_hover {
      display: flex;
      justify-content: center;
      align-content: center;
      align-items: center;

      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      padding: 0.5rem;
      box-sizing: border-box;

      text-align: center;

      background-color: rgba($color: #ffffff, $alpha: 0.5);
      opacity: 0;

      border-radius: 50%;
      z-index: 1;

      &:hover {
        opacity: 1;
      }
    }
  }
}
