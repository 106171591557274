@import '../../styles/variable';
.post_container {
  padding: 1rem 0;
  overflow-wrap: anywhere;
  .main {
    & > * {
      margin-bottom: 16px;
    }
  }
  .action {
    display: flex;
    justify-content: right;
    padding: 1rem 0rem;
  }
  .title {
    margin-bottom: 0;
  }
  .author_container {
    padding: 0;
  }
  .content {
    text-align: justify;
    &.playable_content {
      animation: fade 0.5s ease;
    }
    p {
      font-size: 16px;
      line-height: 1.5;
      margin-bottom: 0.5rem;
    }
  }
  .text_editor {
    .ql-container {
      font-size: 16px;
      .ql-editor {
        padding: 0;
        text-align: justify;
        p {
          font-size: 16px;
          line-height: 1.5;
          margin-bottom: 0.5rem;
        }
      }
    }
  }
  .cover_image {
    position: relative;
    .ant-image {
      width: 100%;
      img {
        max-height: 48rem;
        object-fit: cover;
      }
    }
    a {
      overflow-wrap: break-word;
      font-size: 0.8em;
      margin: 0;
    }
    &.upload_cover {
      position: relative;
      background-color: #f5f5f5;
      .upload_cover_hover {
        display: flex;
        justify-content: center;
        align-content: center;
        align-items: center;

        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        padding: 0.5rem;
        box-sizing: border-box;

        text-align: center;
        font-weight: bold;

        background-color: rgba($color: #ffffff, $alpha: 0.5);
        opacity: 0;
        z-index: 1;

        &:hover {
          opacity: 1;
        }
      }
      .upload_empty_button {
        position: relative;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
  .group_form_item {
    margin-bottom: 24px;
  }
  .title_input {
    box-sizing: border-box;
    padding: 0;
    font-size: 2.5rem;
    font-weight: 600;
    line-height: 2.5rem;
    color: rgba(0, 0, 0, 0.85);
  }
}
.post_container,
.playable_metadata_container {
  .option_dialog {
    width: fit-content;
    min-width: 320px;
    max-width: 100%;
    p {
      font-size: 16px;
      line-height: 1.5;
      margin-bottom: 0.5rem;
    }
    .option_button {
      cursor: pointer;
      width: 100%;
      height: 100%;
      font-size: 16px;
      border: 1px #ccc solid;
      border-radius: 2px;
      padding: 0.5rem;
      &:hover {
        border: 1px $primary-color solid;
        color: $primary-color;
      }
    }
  }
  .cover_image {
    position: relative;
    .ant-image {
      width: 100%;
      img {
        max-height: 48rem;
        object-fit: cover;
      }
    }
    a {
      overflow-wrap: break-word;
      font-size: 0.8em;
      margin: 0;
    }
    &.upload_cover {
      position: relative;
      background-color: #f5f5f5;
      .upload_cover_hover {
        display: flex;
        justify-content: center;
        align-content: center;
        align-items: center;

        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        padding: 0.5rem;
        box-sizing: border-box;

        text-align: center;
        font-weight: bold;

        background-color: rgba($color: #ffffff, $alpha: 0.5);
        opacity: 0;
        z-index: 1;

        &:hover {
          opacity: 1;
        }
      }
      .upload_empty_button {
        position: relative;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
}

.playable_post_form_container {
  position: relative;
  .action {
    position: absolute;
    z-index: 1;
    & > * {
      padding: 0.5rem;
    }
    &.right {
      right: 0;
    }
  }
}
.text_editor {
  &.border {
    border: 1px #ccc solid;
    .ql-container {
      font-size: 16px;
      .ql-editor {
        p {
          font-size: 16px;
          line-height: 1.5;
          margin-bottom: 0.5rem;
        }
      }
    }
  }
}

.playable_metadata_container {
  .cover_image {
    .ant-image {
      img {
        max-height: 28rem;
      }
    }
  }
}

.node_form_container {
  .name_input {
    box-sizing: border-box;
    padding: 0;
    input {
      font-weight: 600;
    }
  }
}

.post_card {
  .extend_icon {
    position: relative;
  }
  code {
    margin: 0;
  }
}
@keyframes fade {
  from {
    opacity: 0;
    transform: translateY(2em);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
