.story_container {
  padding: 1rem 0;
  overflow-wrap: anywhere;
  .main {
    & > * {
      margin-bottom: 16px;
    }
  }
  .action {
    display: flex;
    justify-content: right;
    padding: 1rem 0rem;
  }
  .title {
    margin-bottom: 0;
  }
  .author_container {
    padding: 0;
  }
  .content {
    text-align: justify;
    p {
      font-size: 16px;
      line-height: 1.5;
      margin-bottom: 0.5rem;
    }
  }
  .text_editor {
    .ql-container {
      font-size: 16px;
      .ql-editor {
        padding: 0;
        text-align: justify;
        p {
          font-size: 16px;
          line-height: 1.5;
          margin-bottom: 0.5rem;
        }
      }
    }
  }
  .cover_image {
    position: relative;
    .ant-image {
      width: 100%;
      img {
        max-height: 48rem;
        object-fit: cover;
      }
    }
    a {
      overflow-wrap: break-word;
      font-size: 0.8em;
      margin: 0;
    }
    &.upload_cover {
      position: relative;
      background-color: #f5f5f5;
      .upload_cover_hover {
        display: flex;
        justify-content: center;
        align-content: center;
        align-items: center;

        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        padding: 0.5rem;
        box-sizing: border-box;

        text-align: center;
        font-weight: bold;

        background-color: rgba($color: #ffffff, $alpha: 0.5);
        opacity: 0;
        z-index: 1;

        &:hover {
          opacity: 1;
        }
      }
      .upload_empty_button {
        position: relative;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
  .group_form_item {
    margin-bottom: 24px;
  }
  .title_input {
    box-sizing: border-box;
    padding: 0;
    font-size: 2.5rem;
    font-weight: 600;
    line-height: 2.5rem;
    color: rgba(0, 0, 0, 0.85);
  }
}
