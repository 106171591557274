.login_container {
  display: flex;
  justify-content: center;
  align-items: center;

  min-height: 100vh;
  max-width: 100vw;
  overflow: auto;
  .ant-card {
    height: fit-content;
    min-width: 300px;
    width: 80%;
    max-width: 425px;
  }
  .logo {
    text-align: center;
    img {
      height: 6rem;
    }
  }
  .login-form {
    margin-top: 1rem;
    overflow: hidden;
  }
  .login-form-forgot {
    float: right;
  }
  .ant-col-rtl .login-form-forgot {
    float: left;
  }
  .login-form-button {
    width: 100%;
  }
  .login_with {
    display: flex;
    justify-content: center;
  }
}
