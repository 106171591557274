@import '../../styles/variable';
.header_container {
  max-height: $header-height;
  &.fullscreen {
    position: fixed;
    width: 100%;
    z-index: 1;
  }
}
.header_item {
  max-height: $header-height;
  box-sizing: border-box;
  padding: 0.5rem;
}
.logo_container {
  display: flex;
  justify-content: center;
}
