@import '../../styles/variable';
.footer_container {
  height: $footer-height;
  display: flex;
  justify-content: center;
  align-items: center;
  &.fullscreen {
    display: none;
  }
}
.footer_item {
  max-height: $footer-height;
  box-sizing: border-box;
  padding: 0.5rem;
}
