@import '../../styles/variable';
.logo {
  font-family: 'Poiret One', cursive;
  max-height: $header-height;
  h1 {
    margin: 0;
    font-size: 2rem;
    color: #a69177;
    text-shadow: 1px 1px 2px #a69177;
  }
}
